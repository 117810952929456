#homeHeader {
    padding: 10px 5px;

    .nav-item {
        margin-top: 10px;
        color: red;
    }

    .home_header_link {
        text-decoration: none;
        padding: 5px;
        margin-left: 15px;
        margin-right: 15px;
        color: #191717;
        border-bottom: 5px solid transparent;

        &:hover {
            border-bottom: 5px solid #106bad;
        }

        &.active {
            background: #0085e4;
            color: white;
            border-radius: 5px;
            padding: 10px 15px 5px;
            font-weight: 400;
            font-size: 16px;

            &:hover {
                background: #106bad;
                border-bottom: 5px solid transparent;
            }
        }
    }
}

@media only screen and (max-width: 600px) {}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
        flex-direction: row-reverse;
    }
}